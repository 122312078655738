<template>
  <div class="">
    <v-card class="m-0 p-2">
      <p class="m-0 p-0 mb-2 ms-2 fs-13pt">
        <span class="me-5">Acompanhamento das Inscrições Docentes</span>
        <v-badge inline :content="inscricoesVet.length">
          <span class="fs-9pt">inscritos</span>
        </v-badge>
      </p>
      <v-text-field class="m-0 p-0 mb-2" v-model="search2" append-icon="mdi-magnify" label="Filtrar Inscrições" solo dense hide-details></v-text-field>
      <v-data-table dense sort-by="nome" :sort-desc="false":headers="headers1" :items="inscricoesVet" :search="search2" :items-per-page="10">
        <template v-slot:item="{ item }">
          <tr class="fs-9pt">
            <td class="fs-9pt">
              <span class="fs-10pt fw-500">{{item.marca}}</span>
            </td>
            <td class="fs-9pt text-truncate py-1">
              <!-- <docente :userID="item.id" :key="item.id"/> -->
              <v-container fluid class="m-0 p-0 py-1">
                <v-row dense no-gutters justify="start" align="center" class="m-0 p-0">
                  <v-col cols="auto" class="m-0 p-0">
                    <avataronline :key="item.id" :userID="item.id" :size="30"/>
                  </v-col>
                  <v-col class="m-0 p-0">
                    <p class="m-0 p-0 ms-2 line-height-2 fs-9pt fw-500 f-poppins">
                      <span class="">{{item.nome}}</span>
                    </p>
                    <p class="m-0 p-0 ms-2 line-height-3 fs-10pt fw-300 f-sanspro">
                      <v-icon class="fs-10pt me-1">email</v-icon>
                      <span class="">{{item.email}}</span>
                    </p>
                    <p class="m-0 p-0 ms-2 line-height-3 fs-9pt fw-400 f-sanspro">
                      <v-icon class="fs-10pt me-1">school</v-icon>
                      <span class="">{{item.titulacao}}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </td>
            <td class="fs-9pt">
              <span v-for="(area, key, index) in item.areas" :key="key">
                <tagarea class="me-1" :sigla="areas[key].sigla"/>
              </span>
            </td>
            <td class="fs-9pt">
              <tooltipsucs :ucs="item.ucs20221Sel" />
            </td>
            <td class="fs-9pt">
              <tooltipsucs :ucs="item.ucs20222Sel" />
            </td>
            <td class="fs-9pt">
              <span class="fs-10pt fw-500" v-c:V v-if="item.confirmaInscricao != undefined">
                <v-icon class="fs-12pt" v-c:V>check</v-icon>
                SIM
              </span>
              <span class="fs-10pt fw-300" v-c:D v-if="item.confirmaInscricao == undefined">
                <v-icon class="fs-12pt" v-c:D>error</v-icon>
                NÃO
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "",
  components: {
    //'docente': () => import('./docente.vue'),
    'avataronline': () => import('@/components/avatar-online.vue'),
    'tagarea': () => import("./inscricaoUCD/tagArea.vue"),
    'tooltipsucs': () => import("./tooltip-ucs.vue"),
  },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      areas: {},
      inscricoes: {},
      inscricoesVet: [],
      search2: "",
      headers1: [
        { text: 'Marca', align: 'start', sortable: true, value: 'marca'},
        { text: 'Docente', align: 'start', sortable: true, value: 'nome'},
        { text: 'Áreas de Atuação', align: 'start', sortable: true, value: 'areas', width: '170px' },
        { text: 'UCDs 2022/1', align: 'start', sortable: false, value: 'ucds' },
        { text: 'UCDs 2022/2', align: 'start', sortable: false, value: 'ucds' },
        { text: 'Insc.Concluída', align: 'start', sortable: true, value: 'confirmaInscricao'},
      ],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/areas').on('value',function(snapshot) {
      self.areas = snapshotValToArray(snapshot.val());
      console.log("self.areas",self.areas);
      //self.build();
    });

    rdb.ref("/curriculo/2021S2/inscricoesUCD/").on('value',function(snapshot) {
      var inscricoes = snapshot.val();
      if(inscricoes != null) {
        self.inscricoes = inscricoes;
        self.inscricoesVet = [];
        for(var idx in inscricoes) {
          inscricoes[idx].id = idx;
          self.addInscricao(self.inscricoesVet,inscricoes[idx]);
        }
        console.log("self.inscricoes ["+Object.keys(self.inscricoes).length+"]",self.inscricoes);
      } else {
        self.inscricoes = {};
      }
    });

    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    addInscricao(vet, inscricao) {
      rdb.ref('/usuarios/pessoas/'+inscricao.id+'/nome').once('value',function(snapshot) {
        inscricao.nome = snapshot.val();
        rdb.ref('/usuarios/pessoas/'+inscricao.id+'/email').once('value',function(snapshot) {
          inscricao.email = snapshot.val();
          vet.push(inscricao);
        });
      });
    },

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
